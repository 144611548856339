import {
  COMMAND_K_LISTENER_ENABLED,
  DATADOG_ENABLED,
  MOCKS_ENABLED,
  STATSIG_ENABLED,
} from './environment';

export async function loadModules() {
  const promises: Promise<void>[] = [];

  if (DATADOG_ENABLED) {
    promises.push(
      import('./datadog').then(({ setupDatadog }) => setupDatadog())
    );
  }

  if (STATSIG_ENABLED) {
    promises.push(
      import('./statsig').then(({ statsigAdapter }) =>
        statsigAdapter.initialize()
      )
    );
  }

  if (MOCKS_ENABLED) {
    promises.push(import('./msw').then(({ setupMsw }) => setupMsw()));
  }

  if (COMMAND_K_LISTENER_ENABLED) {
    promises.push(
      import('./commandKListener').then(({ setupCommandKListener }) =>
        setupCommandKListener()
      )
    );
  }

  // We want the app to render even if some modules fail to load.
  // This is important because these modules might be non-critical features (like analytics),
  // and their failure should not prevent the app from rendering.
  await Promise.allSettled(promises);
}
