import { loadModules } from './app/utils/modules';

// It's crucial to render the App after all modules have attempted to load,
// ensuring that any foundational or setup code within these modules has been executed.
loadModules().then(async () => {
  const [{ createRoot }, { App }] = await Promise.all([
    await import('react-dom/client'),
    await import('./app/App'),
  ]);

  const root = createRoot(document.getElementById('root') as HTMLElement);

  root.render(<App />);
});
