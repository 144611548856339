function parseBoolean(value?: string) {
  return !!value;
}

export const VERSION = import.meta.env.VITE_VERSION;

export const MODE = import.meta.env.MODE;

export const IS_DEV = import.meta.env.MODE === 'development';

export const IS_PROD = import.meta.env.MODE === 'production';

export const IS_TEST = import.meta.env.MODE === 'test';

export const IS_IN_IFRAME = window.self !== window.top;

export const ENABLE_MOCKS_INSIDE_IFRAME = parseBoolean(
  import.meta.env.VITE_ENABLE_MOCKS_INSIDE_IFRAME
);

export const ENABLE_MOCKS_OUTSIDE_IFRAME = parseBoolean(
  import.meta.env.VITE_ENABLE_MOCKS_OUTSIDE_IFRAME
);

export const MOCKS_ENABLED =
  !IS_PROD &&
  ((IS_IN_IFRAME && ENABLE_MOCKS_INSIDE_IFRAME) ||
    (!IS_IN_IFRAME && ENABLE_MOCKS_OUTSIDE_IFRAME));

export const COMMAND_K_LISTENER_ENABLED = parseBoolean(
  import.meta.env.VITE_COMMAND_K_LISTENER_ENABLED
);

export const DATADOG_APP_ID = import.meta.env.VITE_DATADOG_APP_ID;

export const DATADOG_TOKEN = import.meta.env.VITE_DATADOG_TOKEN;

export const DATADOG_ENV = import.meta.env.VITE_DATADOG_ENV;

export const DATADOG_ENABLED = !!DATADOG_APP_ID && !!DATADOG_TOKEN;

export const STATSIG_SDK_KEY = import.meta.env.VITE_STATSIG_SDK_KEY;

export const STATSIG_ENABLED = !!STATSIG_SDK_KEY;

export const APP_VERSION = import.meta.env.VITE_APP_VERSION;
